$green: #25a343;
$dark_green: darken($green, 10%);

body {
    font-family: 'Open Sans', sans-serif;
    font-size: 13px;
    font-weight: 400;
    background: #fff;
    color: #000;
    text-align: justify; }

body.dark,
body.night {
    background: #242424;
    color: #f2f2f2;

    h1 > a {
        color: #fff; }

    a {
        color: #bfccff; }

    .item {
        .subtitle,
        .date {
            color: lighten(#737373,40%); } }

    [class*='icon'] {
        color: #fff; } }

[class*='icon'] {
    font-size: 40px;
    margin-right: 10px; }

.js_warning {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background: red;
    color: #fff;
    font-weight: bold; }

.ribbon {
    position: absolute;
    top: 0;
    right: 0;
    width: 200px;
    height: 200px;
    overflow: hidden;
    .content {
        position: absolute;
        right: -175px;
        top: 35px;
        display: inline-block;
        font-size: 220%;
        font-weight: bold;
        padding: 6px 180px;
        text-align: center;
        color: #fff;
        background-color: $green;
        transform: rotate(45deg);
        .notice {
            font-size: 12px;
            display: block; } } }

.container {
    max-width: 520px;
    margin: 0 auto; }

h1, h2, h3, h4, b {
    margin: 0;
    font-weight: bold; }

h1 {
    text-align: center;
    margin: 20px 0 0 0;
    font-size: 320%;
    a {
        color: #000;
        text-decoration: none; } }

h2 {
    font-size: 156%;
    text-align: left; }

h3 {
    font-size: 120%;
    text-align: left; }

h4 {
    font-size: 120%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    .icon,
    canvas {
        margin-right: 10px; } }




.fast_email {
    text-align: center;
    margin-top: 10px; }

.columns-2 {
    column-count: 2;
    column-span: all;
    column-width: 50%;
    @media screen and (max-width: 520px) {
        column-count: 1;
        column-width: 100%; }
    > ul {
        column-break-inside: avoid; } }

ul.columns-2 > li {
    -webkit-column-break-inside: avoid;
    column-break-inside: avoid; }


.item,
.details {
    margin: 10px 0; }

.item {
    .title {
        font-size: 120%;
        font-weight: bold; }
    .subtitle,
    .date {
        color: #737373;
        font-size: 80%; } }

.details {
    .field {
        display: inline-block;
        width: 100px;
        margin-right: 10px;
        text-align: right; } }

ul {
    padding-left: 15px;
    margin-top: 0; }

.skills {
    clear: both;
    ul {
        padding: 0;
        li {
            margin: 0 0 0 20px;
            list-style: none;
            display: block;
            position: relative;
            &:before {
                margin-left: -14px;
                margin-right: 8px;
                content: ' ';
                display: inline-block;
                width: 8px;
                height: 8px;
                border-radius: 100%;
                box-shadow: inset 0 0 0 10px $green; }

            > ul > li:before {
                position: absolute;
                top: -1px;
                bottom: -1px;
                left: 0;
                width: 5px;
                height: 100%;
                border-radius: 0; } } }
    > ul {
        margin: 0 0 20px 0;
        > li:first-child {
            &:before {
                box-shadow: inset 0 0 0 0 #fff; }
            font-weight: 700;
            margin: 0; } } }

.certificates {
    font-size: 80%;
    margin: 0;
    padding-left: 10px;
    .heading {
        font-weight: bold;
        list-style: none; } }

.certificates,
.skills {
    text-align: left; }

.smallprint {
    font-size: 70%; }

.text_center,
.text_centre {
        text-align: center!important; }




.print {
    display: none; }

.center {
    text-align: center; }

.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0; }
.spacer {
    clear: both;
    display: block;
    height: 20px; }

.gallery {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 15px;
    width: 100%;
    max-width: 960px;
    margin: 20px auto;

    .span-1 {
        grid-column: span 1; }
    .span-2 {
        grid-column: span 2; }

    @media screen and (max-width: 769px) {
        .span-1,
        .span-2 {
            grid-column: span 2; } }
    @media screen and (max-width: 520px) {
        .span-1,
        .span-2 {
            grid-column: span 4; } }

    picture {
        display: block;
        border: 1px solid #d0d0d0;
        background: #f2f2f2;
        padding: 10px;
        box-sizing: border-box;

        /* Centre item(s) inside */
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;

        * {
            max-width: 100%; } }
    .lightbox {
        display: none!important; } }



#lightbox {
    background: rgba(0,0,0,.8);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    > div {
        position: relative;
        &::before {
            content: 'X';
            font-family: Arial;
            color: white;
            cursor: pointer;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;
            margin-right: -30px;
            padding: 10px; } }
    img {
        height: 90vh;
        width: auto;
        box-shadow: 0px 0px 20px 5px rgba(0,0,0,0.75); } }


@media screen and (max-width: 520px) {
    body {
        margin: 0 20px; }
    .nomobile {
        display: none; }
    h1 {
        margin-top: 60px; }
    h2 {
        margin-bottom: 60px; } }
